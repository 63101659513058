import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useRef,
  ElementRef,
  useMemo,
} from 'react'
import { UpgradePremiumFlow } from '@/components/wizard/Flows/UpgradePremium'
import { ManageSubscriptionFlow } from '@/components/wizard/Flows/ManageSubscription'
import {
  PremiumSubscriptionContextInterface,
  PremiumUpgradeReason,
} from './types'
import { useAuthContext } from '../Auth'

const PremiumSubscriptionContext = createContext<
  PremiumSubscriptionContextInterface | undefined
>(undefined)

type PremiumSubscriptionProviderProps = {
  children: ReactNode
}

export const PremiumSubscriptionProvider = ({
  children,
}: PremiumSubscriptionProviderProps) => {
  const { isSubscribed, subscriptionStatusLoaded, subscription, user } =
    useAuthContext()

  const upgradePremiumFlow = useRef<ElementRef<typeof UpgradePremiumFlow>>(null)
  const manageSubscriptionFlow =
    useRef<ElementRef<typeof ManageSubscriptionFlow>>(null)

  const openUpgradePremiumFlow = useCallback((reason: PremiumUpgradeReason) => {
    upgradePremiumFlow.current?.trigger(reason)
  }, [])

  const openManageSubscriptionFlow = useCallback(() => {
    manageSubscriptionFlow.current?.trigger()
  }, [])

  const isLifeTimeUser = useMemo(
    () => user?.isEarlyUser || false,
    [user?.isEarlyUser],
  )

  const subscriptionExpiry =
    subscription?.subscriber?.entitlements?.pro?.expires_date

  const unSubscriptionDetected = useMemo(() => {
    if (
      subscription?.subscriber?.subscriptions &&
      Object.keys(subscription?.subscriber?.subscriptions).length
    ) {
      // get first subscription
      const currentSubscription = Object.values(
        subscription?.subscriber?.subscriptions,
      )[0]

      return currentSubscription.unsubscribe_detected_at || null
    }
    return null
  }, [subscription])

  return (
    <PremiumSubscriptionContext.Provider
      value={{
        subscribed: isSubscribed,
        openUpgradePremiumFlow,
        openManageSubscriptionFlow,
        loaded: subscriptionStatusLoaded,
        subscription,
        subscriptionExpiry,
        isLifeTimeUser,
        unSubscriptionDetected,
      }}
    >
      {children}
      <UpgradePremiumFlow ref={upgradePremiumFlow} />
      <ManageSubscriptionFlow ref={manageSubscriptionFlow} />
    </PremiumSubscriptionContext.Provider>
  )
}

export const usePremiumSubscription = () => {
  const context = useContext(PremiumSubscriptionContext)
  if (!context) {
    throw new Error(
      'usePremiumSubscription must be used within a PremiumSubscriptionProvider',
    )
  }
  return context
}
