import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { PaymentPopup, UpgradePopup } from '@/components/ui'
import { PaymentReason } from '@/constants/payments'
import { Popup } from '@/components/uiJar/popup'
import { PremiumUpgradeReason } from '@/contexts/PremiumSubscription/types'
import { PremiumCongratulationsPopup } from '../Popups'

export const UpgradePremiumFlow = forwardRef<{
  trigger: (reason?: PremiumUpgradeReason) => void
}>((_, ref) => {
  const [step, setStep] = useState(0)
  const [isTriggered, setTriggered] = useState(false)
  const [reason, setReason] = useState<PremiumUpgradeReason | undefined>()
  const router = useRouter()

  const handleClose = () => {
    setStep(0)
    setTriggered(false)
    if (reason !== PremiumUpgradeReason.SETTING) router.push('/explore')
  }

  useEffect(() => {
    const flow = new URLSearchParams(window.location.search).get('flow')
    if (flow && flow === 'upgrade') {
      setStep(1)
      setTriggered(true)
    }
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: (reason) => {
          setReason(reason)
          setTriggered(true)
        },
      }
    },
    [],
  )

  if (!isTriggered) return null

  const returnUrl = `${
    new URL(location.pathname, location.href).href
  }?flow=upgrade`

  return (
    <>
      <UpgradePopup
        opened={step === 0}
        onProceed={() => setStep(1)}
        onClose={handleClose}
        reason={reason}
      />
      <Popup fullScreenInMobile isOpen={step === 1}>
        <PaymentPopup
          type={PaymentReason.PremiumSubscription}
          returnUrl={returnUrl}
          onClose={handleClose}
          onSuccess={() => {
            setStep(2)
          }}
        />
      </Popup>
      <Popup fullScreenInMobile isOpen={step === 2}>
        <PremiumCongratulationsPopup
          onClose={() => {
            window.location.href = `${
              new URL(location.pathname, location.href).href
            }`
          }}
        />
      </Popup>
    </>
  )
})

UpgradePremiumFlow.displayName = 'UpgradePremiumFlow'
