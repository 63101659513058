import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { noop } from 'lodash'
import { DateTime } from 'luxon'
import { IUser } from '@/models/IUser'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import useApi from '@/hooks/useApi'
import { tracker } from '@/utils/tracking'
import { checkIsAuthRoute, checkIsGatedRoute } from '@/utils/auth'
import { ISubscription } from '@/models/ISubscription'
import { UserRole } from '@/constants/users'
import { AuthContextInterface } from './types'

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface,
)

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { api } = useApi()
  const router = useRouter()
  const [user, setUser] = useState<IUser>()
  const [wizardsCount, setWizardsCount] = useState(0)
  const [unreadCount, setUnreadCount] = useState(0)
  const [subscriptionStatusLoaded, setSubscriptionStatusLoaded] =
    useState(false)
  const [subscription, setSubscription] = useState<ISubscription>()
  const [storedToken, storeToken] = useLocalStorage<string | undefined>(
    'token',
    undefined,
  )
  const [token, setToken] = useState(storedToken)

  useEffect(() => {
    const isAuthRoute = checkIsAuthRoute(router.pathname)
    const isGatedRoute = checkIsGatedRoute(router.pathname)

    if (isAuthRoute && token) router.replace('/explore')
    else if (isGatedRoute && !token) router.replace('/auth/login')

    if (token && !user) {
      api.authorize(token)
      api.auth
        .me()
        .then((response) => setUser(response))
        .catch(noop)
      api.auth
        .checkSubscription()
        .then((data) => {
          setSubscription(data)
          setSubscriptionStatusLoaded(true)
        })
        .catch((_error) => setSubscriptionStatusLoaded(true))
    }
  }, [token, router.pathname])

  useEffect(() => {
    user && tracker.trackIdentity(user)
  }, [user])

  const getWizardCount = useCallback(() => {
    api.wizard.getUserWizardsCount().then((response) => {
      setWizardsCount(response.wizards)
      setUnreadCount(response.unread)
    })
  }, [api.wizard])

  useEffect(() => {
    user && user?.role !== UserRole.Lawyer && getWizardCount()
  }, [user, getWizardCount])

  const handleToken = (token: string) => {
    setToken(token)
    storeToken(token)

    if (token) {
      api.authorize(token)
      api.auth
        .checkSubscription()
        .then((data) => {
          setSubscription(data)
          setSubscriptionStatusLoaded(true)
        })
        .catch((_error) => setSubscriptionStatusLoaded(true))
    }
  }

  const handleUser = useCallback((user: IUser) => {
    setUser(user)
  }, [])

  const isSubscribed = useMemo(() => {
    if (!subscriptionStatusLoaded) return false
    if (user && user.isEarlyUser) return true

    if (!subscription) return false
    if (!subscription.subscriber.entitlements.pro) return false

    const { pro } = subscription.subscriber.entitlements
    return DateTime.fromISO(pro.expires_date) > DateTime.now()
  }, [subscription, user, subscriptionStatusLoaded])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken: handleToken,
        user,
        setUser: handleUser,
        isSubscribed,
        subscription,
        subscriptionStatusLoaded,
        wizardsCount,
        unreadMessagesCount: unreadCount,
        getWizardCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextInterface => {
  return useContext(AuthContext)
}
